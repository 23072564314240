.logo-wrapper {
  background: #000;
  position: fixed;
  height: 100vh;
  padding: 0;
  padding-top: 300px;

  img {
    position: absolute;
    width: 200px;
    right: -100px;
    top: 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      &.active {
        background: #fff;

        a {
          color: #000;
        }
      }

      a {
        color: #fff;
        padding: 10px;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    height: initial;
    padding: 0 15px;

    &:before {
      content: "";
      background: #fff;
      position: absolute;
      right: 0;
      left: 50%;
      top: 0;
      bottom: 0;
      z-index: 1;
    }

    img {
      z-index: 5;
      position: sticky;
      margin: 0 auto;
      display: block;
      max-width: 150px;
    }

    .menu {
      display: none;
    }
  }
}

.content {
  padding: 40px 30px 40px 100px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  &.page-1 {
    padding-bottom: 100px;
  }

  .title {
    text-align: center;

    h1 {
      text-transform: uppercase;
      font-size: 48px;
    }
  }

  .symbol {
    font-size: 150px;
  }

  .links {
    a {
      padding: 0 20px;
      font-size: 20px;
      color: #000;
    }
  }

  iframe {
    width: 100%;
    min-height: 500px;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;

    .scroll-icon {
      display: none;
    }
  }
}

// Example 2
@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: translateY(0.75em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.scroll-icon {
  display: block;
  position: absolute;
  bottom: 50px;
  height: 50px;
  width: 30px;
  border: 2px solid #000;
  border-radius: 15px;

  .dot {
    display: block;
    position: absolute;
    left: 50%;
    background: #000;
    height: 6px;
    width: 6px;
    top: 10px;
    margin-left: -3px;
    border-radius: 50%;
    transform-origin: top center;
    backface-visibility: hidden;
    animation: scroll 2s ease-out infinite;
  }
}
